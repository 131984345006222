// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*:not(.react-icon) {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*{
  margin: 0;
}
/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}


body {
  font-family: var(--font-family);
  background-color: var(--background-color);
}

a {
  color: white;
  text-decoration: none;
}

::selection {
  background: none;
}

`, "",{"version":3,"sources":["webpack://./src/app/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,SAAS;AACX;AACA,gDAAgD;AAChD;EACE,aAAa;AACf;;AAEA,4CAA4C;AAC5C;EACE,wBAAwB;EACxB,qBAAqB;AACvB;;;AAGA;EACE,+BAA+B;EAC/B,yCAAyC;AAC3C;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["*:not(.react-icon) {\n  box-sizing: border-box;\n  scroll-behavior: smooth;\n}\n\n*{\n  margin: 0;\n}\n/* Hide scrollbar for Chrome, Safari and Opera */\n*::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n* {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n\nbody {\n  font-family: var(--font-family);\n  background-color: var(--background-color);\n}\n\na {\n  color: white;\n  text-decoration: none;\n}\n\n::selection {\n  background: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
