import React from 'react' ;
import "./errorPage.css" ;


const ErrorPage = () => {
  
    return (
      <div className = "error_page">
        This page is not available.
      </div>
    )
  }
  
  export default ErrorPage