// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/projectimages/portfoliowebsite.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Abel&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
    --text-font: "Abel", sans-serif;
    --header-font: "Rubik",sans-serif;

    --linear-bg: linear-gradient(90deg, rgba(2, 0, 7, 1) 30%, rgba(159, 102, 205, 1) 100%);;
    
    --text-color: #e8dff1;
    --primary-color: #9a71c1;
    --secondary-color: #120b19;
    --accent-color: #7949a7;
    --background-color: #000000;
    --contact-background-color: rgba(255,255,255,0.05);

    --project-grid-primary-color: #040205;
    --project-grid-secondary-color: #17111D;

    --hover-transition: all 0.1s ease-out 0.05s;
    --navbar-text-hover-transition: transform 0.7s ease-in-out;
    --icon-hover-transition: rotate(215deg) scale(1.25);

    --logo-translate-x: 8%;
    --logo-translate-y: 8%;

    --project-background: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
    
}`, "",{"version":3,"sources":["webpack://./src/app/index.css"],"names":[],"mappings":"AAEA;IACI,+BAA+B;IAC/B,iCAAiC;;IAEjC,sFAAsF;;IAEtF,qBAAqB;IACrB,wBAAwB;IACxB,0BAA0B;IAC1B,uBAAuB;IACvB,2BAA2B;IAC3B,kDAAkD;;IAElD,qCAAqC;IACrC,uCAAuC;;IAEvC,2CAA2C;IAC3C,0DAA0D;IAC1D,mDAAmD;;IAEnD,sBAAsB;IACtB,sBAAsB;;IAEtB;;AAEJ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Abel&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Rubik&display=swap\");\n:root{\n    --text-font: \"Abel\", sans-serif;\n    --header-font: \"Rubik\",sans-serif;\n\n    --linear-bg: linear-gradient(90deg, rgba(2, 0, 7, 1) 30%, rgba(159, 102, 205, 1) 100%);;\n    \n    --text-color: #e8dff1;\n    --primary-color: #9a71c1;\n    --secondary-color: #120b19;\n    --accent-color: #7949a7;\n    --background-color: #000000;\n    --contact-background-color: rgba(255,255,255,0.05);\n\n    --project-grid-primary-color: #040205;\n    --project-grid-secondary-color: #17111D;\n\n    --hover-transition: all 0.1s ease-out 0.05s;\n    --navbar-text-hover-transition: transform 0.7s ease-in-out;\n    --icon-hover-transition: rotate(215deg) scale(1.25);\n\n    --logo-translate-x: 8%;\n    --logo-translate-y: 8%;\n\n    --project-background: url(\"../assets/projectimages/portfoliowebsite.png\")\n    \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
