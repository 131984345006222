// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf{
    background-color: var(--background-color);
    display: flex;
    flex-direction: column-reverse;
}

.annotationLayer{
    display: none;
}

.pdf__buttons{
    display: flex;
    margin: 1% 0%;
}

.pdf__buttons > *{
    flex: 1;
} 

.button__return-pdf, .button__download-pdf{
    margin-top: 0.5rem;
    font-size: 1.5rem;
    color: var(--text-color);
    font-family: var(--text-font);
    background-color: var(--secondary-color);
    margin-inline: 1%;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    text-align: center;
}





`, "",{"version":3,"sources":["webpack://./src/app/components/resume/resume.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,OAAO;AACX;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,wBAAwB;IACxB,6BAA6B;IAC7B,wCAAwC;IACxC,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".pdf{\n    background-color: var(--background-color);\n    display: flex;\n    flex-direction: column-reverse;\n}\n\n.annotationLayer{\n    display: none;\n}\n\n.pdf__buttons{\n    display: flex;\n    margin: 1% 0%;\n}\n\n.pdf__buttons > *{\n    flex: 1;\n} \n\n.button__return-pdf, .button__download-pdf{\n    margin-top: 0.5rem;\n    font-size: 1.5rem;\n    color: var(--text-color);\n    font-family: var(--text-font);\n    background-color: var(--secondary-color);\n    margin-inline: 1%;\n    border-radius: 10px;\n    border: none;\n    cursor: pointer;\n    text-align: center;\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
